<template>
  <div class="coupon-content">
    <div @click="toGetCoupon" class="get-coupon-btn">领券中心</div>
    <el-tabs v-model="activeName" type="card" @tab-click="getCoupon">
      <el-tab-pane label="未使用" name="101"></el-tab-pane>
      <el-tab-pane label="已使用" name="102"></el-tab-pane>
      <el-tab-pane label="已失效" name="103"></el-tab-pane>
    </el-tabs>
    <el-table :data="tableData">
      <el-table-column prop="type_str" label="优惠券类型" align="center" />
      <el-table-column prop="use_money" label="优惠券门槛金额" align="center" />
      <el-table-column prop="money" label="优惠券金额" align="center" />
      <el-table-column prop="time_str" label="发放时间" align="center" />
      <el-table-column prop="desc" label="优惠券备注" align="center" />
      <el-table-column prop="date_range" label="使用时间" align="center" />
      <!-- <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="check flex_c_c" @click="handleClick(scope.row)">查看</div>
        </template>
      </el-table-column> -->
    </el-table>
    <pagination :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      activeName: "101", //101未使用 102已使用 103已过期
      tableData: [],
      total: 0,
      page: 1,
      page_size: 10,
    };
  },
  created() {
    this.getCoupon();
    console.log("菜单----", this.$store.state.menuData);
  },
  methods: {
    getCoupon() {
      this.$api("account.getCoupon", {
        status: this.activeName,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.count;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getCoupon();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getCoupon();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    toGetCoupon() {
      let menuList = this.$store.state.menuData;
      let ind = menuList.findIndex((item) => item.title == "领券中心");
      this.$store.commit("CHANGE_PAGENAME", ["领券中心"]);
      this.$store.commit("CHANGE_TAB", ind);
      this.$router.push({
        path: "/index/getcoupon",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.coupon-content {
  // background-color: bisque;
  position: relative;
  .get-coupon-btn {
    z-index: 99;
    width: 80px;
    height: 30px;
    cursor: pointer;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    position: absolute;
    right: 10px;
    top: 6px;
    background-color: @themeColor;
    color: #fff;
  }
}
</style>
